import Vue from "vue";

import "jquery";
import "popper.js";
import "bootstrap";
import { BootstrapVue } from "bootstrap-vue";
import router from "./router"
import Main from "./Main.vue"
import vueHljs from "vue-hljs";
import axios from 'axios';

import store from "./store"
import Configuration from "./services/configuration";
import { initUserManager } from './services/user-manager';
import { authService } from './services/auth-service';

import "@/assets/css/vue-hljs.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./main.scss";

import "@/assets/css/hp-custom.css"

Vue.config.productionTip = false

Vue.use(BootstrapVue);
Vue.use(vueHljs);

/**
 * The startup sequence of the MWS client is as follows:
 * 1. Request the contents of the "app-config.json".
 *    This file contains the version, the OpenID client ID and the URL to the authority service.
 * 
 * 2. The user manager is initialized.
 *    At this step the OpenID settings are set.
 * 
 * 3. The login sequence is started.
 * 
 * 4. The MWS client is started, only when the user is logged in.
 */
axios.get("/app-config.json").then(({ data }) => {
  // Set the version
  Configuration.version = data.version;
  Configuration.authority = data.authority;
  Configuration.clientId = data.clientId;

  // Load the URL
  let url = new URL(window.location.href);

  // Initialize the user manager
  initUserManager()
    .then((userManager) => {
      // Initialize the auth service
      authService.init(userManager);

      // Verify if the URL contains a code query parameter. This is set after the user logged in successfully.
      if (url.searchParams.get('code')) {
        // Verify if a token can be requested
        authService.handleLoginRedirect()
          .then(() => {
            // Start the application
            startApplication(data);
          })
          .catch(error => {
            // Start the login flow again
            authService.login();
          });
      }
      // Otherwise; verify if the user is logged in
      else {
        authService.isUserLoggedIn()
          .then((isLoggedIn: boolean) => {
            // Start the application when the user is logged in
            if (isLoggedIn) {
              startApplication(data);
            }
            // Otherwise; start the login flow
            else {
              authService.login();
            }
          });
      }
    });
});

function startApplication(data: any) {
  authService.getUser()
    .then(user => {
      // Update the URL
      history.pushState({}, "route", window.location.origin);

      // Update the store with the user reference
      store.state.user = user;

      // Start the application
      new Vue({
        router,
        store,
        render: h => h(Main)
      }).$mount("#app");
    });
}