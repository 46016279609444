import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import Configuration from './configuration';

export function initUserManager(): Promise<UserManager> {
  return new Promise((resolve, reject) => {
    /**
     * Config for the oidc client.
     */
    const settings = {
      // Where the tokens will be stored
      userStore: new WebStorageStateStore({
        prefix: "oidc",
        store: window.sessionStorage
      }),

      // URL to the authority server (including realm)
      authority: Configuration.authority,

      // The name of the client in Keycloak setup for this service
      client_id: Configuration.clientId,

      // Where to redirect the user to after successful authentication
      redirect_uri: `${window.location.protocol}//${window.location.host}/`,

      // Where to redirect the user to after logging the user out
      post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}/`,

      // Indicate the the authorization code flow should be used
      response_type: 'code',

      // "openid" tells the server that this client uses oidc for authentication
      scope: 'openid roles',

      // Disable monitoring the session
      monitorSession: false,

      // Enable automatic (silent) renewal of the access token
      automaticSilentRenew: true,
      silentRequestTimeoutInSeconds: 10,

      revokeTokensOnSignout: true,
    }

    const userManager = new UserManager(settings)
    resolve(userManager);
  })
}