
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({})
export default class Styleguide extends Vue {
  mounted() {
    window.scrollTo(0, 0);
  }

  handleAnchor(elId: string) {
    let el = document.getElementById(elId);
    const y = el.getBoundingClientRect().top + window.pageYOffset - 80;
    window.scrollTo({ top: y, behavior: "smooth" });
  }
}
