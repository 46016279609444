import { User, UserManager } from 'oidc-client-ts'

/**
 * Class to encapsulate all authentication related logic.
 */
class AuthService {

  private userManager: UserManager;

  init(userManager: UserManager) {
    this.userManager = userManager;
  }

  /**
   * Initiate the login process.
   */
  login() {
    this.userManager.signinRedirect()
      .catch(error => console.log(error));
  }

  /**
   * Initiate the logout process.
   */
  logout() {
    this.getUser().then(user => {
      if (user && user.id_token) {
        this.userManager.signoutRedirect({ 'id_token_hint': user.id_token })
          .then(() => sessionStorage.clear())
          .catch(error => console.log(error));
      }
      else {
        this.login();
      }
    })
  }

  /**
   * Handles the redirect from the OAuth server after a user logged in.
   */
  handleLoginRedirect() {
    // Returns a promise
    return this.userManager.signinRedirectCallback();
  }

  /**
   * Handles the redirect from the OAuth server after a user logged out.
   */
  handleLogoutRedirect() {
    return this.userManager.signoutRedirectCallback();
  }

  /**
   * Checks whether or not a user is currently logged in.
   *
   * Returns a promise which will be resolved to true/false or be rejected with an error.
   */
  isUserLoggedIn(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.userManager.getUser()
        .then(user => {
          if (user === null || (user && user.expired)) {
            resolve(false);
          }

          resolve(true);
        })
        .catch(error => reject(error));
    });
  }

  /**
   * Get the access token.
   *
   * Can be used to make requests to the backend.
   */
  getAccessToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.userManager.getUser()
        .then(user => {
          resolve(user.access_token);
        })
        .catch(error => reject(error));
    });
  }

  getUser(): Promise<User> {
    return this.userManager.getUser();
  }

  /**
   * Init the event listeners on the userManager
   */
  addEventListeners(): void {
    this.userManager.events.addUserLoaded((user: User) => {
    });
    this.userManager.events.addUserSessionChanged(() => {
    });
    this.userManager.events.addUserSignedOut(() => {
    });
  }

  /**
   * Returns true if the user has at least one of the roles defined above.
   */
  isAuthorized(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getUser()
        .then((user) => {
          if (user && user.profile && user.profile.role) {
            let isAuthorized = true;
            resolve(isAuthorized);
          }
          else {
            resolve(false);
          }
        }, (reason) => {
          reject(reason);
        });
    });
  }
}

/**
* Create and expose an instance of the auth service.
*/
export const authService = new AuthService();