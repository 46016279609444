
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({})
export default class Login extends Vue {
  mounted() {
    window.scrollTo(0, 0);
  }

  isDismissed: boolean = false;

  dismissNotification() {
    this.isDismissed = true;
  }
}
