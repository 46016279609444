
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({})
export default class UIElements extends Vue {
  isDismissed: boolean = false;

  onToggleDropdownMenu() {
    (this.$refs.dropdownExample as HTMLElement).classList.toggle("expand");
  }

  openModal(id: string) {
    if (this.isDismissed == false) {
      this.$bvModal.show(id);
    }
  }

  dismissNotification() {
    this.isDismissed = true;
  }

  mounted() {
    window.scrollTo(0, 0);
  }

  handleAnchor(elId: string) {
    let el = document.getElementById(elId);
    const y = el.getBoundingClientRect().top + window.pageYOffset - 80;
    window.scrollTo({ top: y, behavior: "smooth" });
  }

  onCreateToastNotification() {
    const vm = new Vue();
    vm.$bvToast.toast("This is an example success notification.", {
      title:
        "Hi! This is a notification example. Try to keep it one or two lines. ",
      variant: "success",
      bodyClass: "d-none",
      autoHideDelay: 500000,
      toaster: "b-toaster-bottom-right",
    });
  }
}
