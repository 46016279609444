import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import Styleguide from '@/views/Styleguide.vue'
import UIElements from '@/views/UIElements.vue'
import Login from '@/views/Login.vue'
import HPLayout from '@/views/HP-layout.vue'
import Guidelines from '@/views/Guidelines.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/styleguide',
    name: 'Styleguide',
    component: Styleguide
  },
  {
    path: '/uielements',
    name: 'UI Elements',
    component: UIElements
  },
  {
    path: '/login',
    name: 'Login Example',
    component: Login
  },
  {
    path: '/hp-layout',
    name: 'HP Layout',
    component: HPLayout
  },
  {
    path: '/guidelines',
    name: 'Guidelines',
    component: Guidelines
  }
  
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
