
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { StoreState } from "../models/models";

@Component({})
export default class Home extends Vue {

  get name(): string {
    let storeState = this.$store.state as StoreState;
    return storeState && storeState.user && storeState.user.profile ? storeState.user.profile.name : '';
  }

  mounted() {
    window.scrollTo(0, 0);
  }

  handleAnchor(elId: string) {
    let el = document.getElementById(elId);
    const y = el.getBoundingClientRect().top + window.pageYOffset - 120;
    window.scrollTo({ top: y, behavior: "smooth" });
  }
}
