
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Configuration from "./services/configuration";

@Component({})
export default class Main extends Vue {
  get version() {
    return Configuration.version;
  }
}
