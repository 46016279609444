import Vue from 'vue'
import Vuex from 'vuex'
import { StoreState } from '../models/models';

Vue.use(Vuex)

export default new Vuex.Store<StoreState>({
    state: new StoreState(),

    mutations: {
    },
    actions: {
    },
    modules: {
    }
});
